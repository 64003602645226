import { Injectable } from '@angular/core';
import { FormErrorsEnum } from '../enum/FormErrors.enum';
import { LocalizationService } from './localization.service';
import { LocalizationKeysEnum } from '../enum/LocalizationKeys.enum';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {

  constructor(private localizationService: LocalizationService) { }

  getErrorMessageReplaceInfo(errorKey: string, values: string[]): string {
    let localizedMessage = this.localizationService.getValidationMessageTranslation(errorKey);
    values.forEach((val) => { localizedMessage = localizedMessage.replace('{}', val);});
    return localizedMessage;
  }

  getLocalizationErrorMessage(errorKey: string): string {
    return this.localizationService.getValidationMessageTranslation(errorKey);
  }

  getErrorsMessages(
    fieldKey: string | null,
    errorKey: string,
    errorObject: any
  ): string[] {
    // TODO: add localization
    // TODO: fix format of messages

    switch (errorKey) {
      case FormErrorsEnum.IncorrectLength: {
        const { actualLength, requiredLength } = errorObject;
        if (fieldKey != null) {
          // TODO: Provide fieldKey and than use Localisation map key -> friendly field name to use errors like "First name is required"
          return [
            this.getErrorMessageReplaceInfo(LocalizationKeysEnum.InCorrectLengthWithFieldName, [fieldKey, requiredLength, actualLength
            ])];
        }
        return [this.getErrorMessageReplaceInfo(LocalizationKeysEnum.InCorrectLength, [requiredLength, actualLength])];
      }

      case FormErrorsEnum.Required: {
        if (fieldKey != null) {
          return [this.getErrorMessageReplaceInfo(LocalizationKeysEnum.RequiredWithFieldName, [fieldKey])];
        }
        return [this.getLocalizationErrorMessage(LocalizationKeysEnum.IsRequired)];
      }
      case FormErrorsEnum.InvalidPhone: {
        return [this.getLocalizationErrorMessage(LocalizationKeysEnum.InvalidPhoneNumber)];
      }
      case FormErrorsEnum.Email: {
        return [this.getLocalizationErrorMessage(LocalizationKeysEnum.InvalidEmailAddress)];
      }
      case FormErrorsEnum.Max: {
        const { max } = errorObject;
        return [this.getErrorMessageReplaceInfo(LocalizationKeysEnum.InvalidMaxValue, [max])];
      }
      case FormErrorsEnum.Min: {
        const { min } = errorObject;
        return [this.getErrorMessageReplaceInfo(LocalizationKeysEnum.InvalidMinValue, [min])];
      }
      case FormErrorsEnum.MaxLength: {
        const { actualLength, requiredLength } = errorObject;
        return [this.getErrorMessageReplaceInfo(LocalizationKeysEnum.InvalidMaxLength, [requiredLength, actualLength])];
      }

      case FormErrorsEnum.Pattern: {
        return [this.getLocalizationErrorMessage(LocalizationKeysEnum.InvalidFieldFormat)];
      }

      case FormErrorsEnum.MinAgeRestriction: {
        return [this.getErrorMessageReplaceInfo(LocalizationKeysEnum.MinAgeRestriction, [errorObject.expectedAge])];
      }

      case FormErrorsEnum.MaxAgeRestriction: {
        return [this.getLocalizationErrorMessage(LocalizationKeysEnum.MaxAgeRestriction)];
      }
    }

    return [];
  }
}
