import { Component, OnInit } from '@angular/core';
import { LeadService } from '../../../services/lead.service';
import { Router } from '@angular/router';
import {
  completeUrl,
  finicityFlowUrl,
  standardFlowUrl,
} from '../../../constants';
import { ManualFilesUploadService } from 'src/app/services/manualFilesUpload.service';
import { FilesService } from 'src/app/services/files.service';
import { RequestDocumentResponseType } from 'src/app/models/responses/request-file.model';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  constructor(
    private readonly leadService: LeadService,
    private readonly router: Router,
    private filesService: FilesService,
    private manualFilesUploadService: ManualFilesUploadService
  ) { }

  ngOnInit(): void { }

  public submit(): void {
    const isPlaidSuccessful = this.filesService.getPlaidFlowSuccessFlag();
    if (this.router.url === standardFlowUrl && !isPlaidSuccessful) {
      const [isValid, errorMessage] = this.verifyManualUpload();
      if (!isValid) {
        this.manualFilesUploadService.errorMessage.next(errorMessage);
        return;
      }
    }

    this.leadService.updateApplicationStepsState();
    this.router.navigateByUrl(
      this.router.url === finicityFlowUrl ? standardFlowUrl : completeUrl
    );
  }

  private verifyManualUpload(): [boolean, string] {
    const files = this.filesService.files.value;
    const bankStatements = files.filter((f) =>
      [
        RequestDocumentResponseType.BankStatement1,
        RequestDocumentResponseType.BankStatement2,
        RequestDocumentResponseType.BankStatement3,
      ].some((t) => f.fileName.includes(t))
    );
    const governmentId = files.find((f) =>
      f.fileName.includes(RequestDocumentResponseType.GovernmentId)
    );
    let errorMessage: string = null;

    if (bankStatements.length < 3 && !governmentId) {
      errorMessage =
        'Please upload business bank statements for the last 3 months and a valid government ID';
    } else if (bankStatements.length < 3 && governmentId) {
      errorMessage =
        'Please upload business bank statements for the last 3 months';
    } else if (bankStatements.length >= 3 && !governmentId) {
      errorMessage = 'Please upload a valid government ID';
    }

    return [errorMessage === null, errorMessage];
  }
}
