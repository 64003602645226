import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../base-api-service';

@Injectable({
  providedIn: 'root',
})

export class PlaidLeadService {
    OPF_BRAND = 'OPF';
    leadId;
    isMck: boolean = false;
    language;
    publicToken;
    constructor(
    private baseApiService: BaseApiService,
    ) {}
  public getPlaidLinkToken(): Observable<string> {
    return this.baseApiService
      .sendData<any, any>('post',
        '/plaid/link-token',
        {
          leadId: this.leadId,
          language: 'en',
          brand: this.OPF_BRAND
        }, false)
      .pipe(map((response: any) => response.linkToken));
  }

  public exchangePlaidPublicToken(publicToken: string): Observable<void> {
    return this.baseApiService.sendData(
      'post',
      '/plaid/exchange-token',
      {
        leadId: this.leadId,
        publicToken
      },
      false
    );
  }
}
