import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';
import { RegisterComponent } from './components/register/register.component';
import { ApplicationFlowComponent } from './components/application-flow/application-flow.component';
import { GeneralInfoComponent } from './components/application-flow/general-info/general-info.component';
import { BusinessInfoComponent } from './components/application-flow/business-info/business-info.component';
import { OwnersInfoComponent } from './components/application-flow/owners-info/owners-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { ReportsComponent } from './components/application-flow/reports/reports.component';
import { UserInfoComponent } from './components/application-flow/user-info/user-info.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { RegistrationHeaderComponent } from './components/shared/registration-header/registration-header.component';
import { AppHeaderComponent } from './components/shared/app-header/app-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { DocusignPageComponent } from './components/application-flow/docusign-page/docusign-page.component';

import { NotifierModule } from 'angular-notifier';

import customNotifierOptions from './notifications/config';
import { UploadButtonComponent } from './components/shared/upload-button/upload-button.component';
import { SafePipe } from './pipes/safe-pipe';
import { TrustPilotComponent } from './components/register/trust-pilot/trust-pilot.component';
import { LoginComponent } from './components/login/login.component';
import { API_BASE_URL } from './services/base-api-service';
import { LoginPopupComponent } from './components/register/login-popup/login-popup.component';
import { ApplicationFlowStepsComponent } from './components/application-flow/application-flow-steps/application-flow-steps.component';
import {
  OwnersInfoFieldErrorsComponent
} from './components/application-flow/owners-info/owners-info-field-errors.component/owners-info-field-errors.component';
import { FilesListComponent } from './components/shared/files-list/files-list.component';
import { FieldErrorsModule } from './components/shared/field-errors/field-errors.module';
import { StandardOrFinicityComponent } from './components/application-flow/reports/standard-or-finicity/standard-or-finicity.component';
import { StandardFlowComponent } from './components/application-flow/reports/standard-flow/standard-flow.component';
import { FinicityFlowComponent } from './components/application-flow/reports/finicity-flow/finicity-flow.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { OverviewPageComponent } from './components/application-flow/overview-page/overview-page.component';
import {
  OverviewAppFlowComponent
} from './components/application-flow/overview-page/components/overview-app-flow/overview-app-flow.component';
import { DeclineComponent } from './components/decline/decline.component';
import { StatementInfoComponent } from './components/application-flow/overview-page/components/statement-info/statement-info.component';
import { CommonModule } from '@angular/common';
import { ResetPasswordMailComponent } from './components/reset-password-mail/reset-password-mail.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { StatusCircleComponent } from './components/application-flow/overview-page/components/status-circle/status-circle.component';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from './components/footer/footer.component';
import { CompletePageComponent } from './components/application-flow/complete-page/complete-page.component';
import { SharedModule } from './components/shared/shared.module';
import { ThemeModule, opfTheme, opfV2Theme, alentezTheme } from './theme';
import { InCollectionsComponent } from './components/application-flow/in-collections/in-collections.component';
import { TokenService } from './services/token-service';
import { FieldSuccessComponent } from './components/shared/field-success/field-success.component';
import { HttpLoaderFactory } from './services/localization.service';
import { FinicityErrorPopupComponent }
  from './components/application-flow/reports/finicity-flow/finicity-error-popup/finicity-error-popup.component';
import { EntryPlaidComponent } from './components/application-flow/reports/plaid-flow/entry/entry.component';
import { LoginPlaidComponent } from './components/application-flow/reports/plaid-flow/login/login.component';

export const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    LoginPlaidComponent,
    EntryPlaidComponent,
    ApplicationFlowComponent,
    ApplicationFlowStepsComponent,
    GeneralInfoComponent,
    BusinessInfoComponent,
    OwnersInfoComponent,
    OwnersInfoFieldErrorsComponent,
    TrustPilotComponent,
    ReportsComponent,
    UserInfoComponent,
    RegistrationHeaderComponent,
    AppHeaderComponent,
    DocusignPageComponent,
    UploadButtonComponent,
    SafePipe,
    LoginPopupComponent,
    FilesListComponent,
    StandardOrFinicityComponent,
    StandardFlowComponent,
    FinicityFlowComponent,
    ResetPasswordMailComponent,
    OverviewPageComponent,
    OverviewAppFlowComponent,
    DeclineComponent,
    StatementInfoComponent,
    ResetPasswordComponent,
    StatusCircleComponent,
    FooterComponent,
    CompletePageComponent,
    InCollectionsComponent,
    FieldSuccessComponent,
    FinicityErrorPopupComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NotifierModule.withConfig(customNotifierOptions),
    FieldErrorsModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(maskConfig),
    ThemeModule.forRoot({
      themes: [opfTheme, opfV2Theme, alentezTheme],
      active: '0'
    }),
    SharedModule,
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiBaseUrl,
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.gtmTag
    },
    environment.monitoring.sentry.enabled ? {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
      }),
    } : undefined,
    environment.monitoring.sentry.enabled ? {
      provide: Sentry.TraceService,
      deps: [Router],
    } : undefined,
    environment.monitoring.sentry.enabled ? {
      provide: APP_INITIALIZER,
      useFactory: (): any => (): any => {},
      deps: [Sentry.TraceService],
      multi: true,
    } : undefined,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ].filter((p): any => !!p),
  bootstrap: [AppComponent],
  exports: [
    TrustPilotComponent,
    ApplicationFlowStepsComponent,
    MatDialogModule,
    FilesListComponent,
  ],
})
export class AppModule {
  constructor(
    tokenService: TokenService,
  ) {
    const userId = tokenService.getUserId();

    if (userId) {
      Sentry.setUser({id: tokenService.getUserId()});
    }
  }
}
