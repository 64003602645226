import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { FlowIdService } from '../../../../services/flow-id.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-standard-or-finicity',
  templateUrl: './standard-or-finicity.component.html',
  styleUrls: ['./standard-or-finicity.component.scss'],
})
export class StandardOrFinicityComponent implements OnInit {
  flowId;
  enableFinicity: boolean;

  constructor(private flowIdService: FlowIdService, private route: Router) {}

  ngOnInit(): void {
    this.enableFinicity = environment.enableFinicity;
    this.flowId = this.flowIdService.getFlowId();
    if (this.flowId === 3) {
      this.route.navigateByUrl('/application/reports/finicity');
    }
    if(this.flowId === '12')
    {
      this.route.navigateByUrl('/application/reports/plaid');
    }
  }
}
