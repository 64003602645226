<div id="embodiement">
    <ng-container *ngIf="showPlaidErrorMessage">
        <div class="plaid-error-message" [innerHTML]=" 'plaid-error-message' | translate"></div>
    </ng-container>
    <div id="whereaction-is" *ngIf="showPlaidContainer">
        <div class="text-center mt-5">
            <div class="entry-info-container">
                <div class="warning-box">
                    <img src="/assets/icons/exclamation-triangle.svg" alt="">
                    <p [innerHTML]=" 'plaid.attention' | translate"></p>
                </div>
                <div class="entry-info-msg" [innerHTML]=" 'plaid.entryInformationMsg' | translate"></div>
            </div>
            <div class="conv-sec-list">
                <div class="conv-sec-title-box">
                    <div class="lock-icon-up">
                        <img src="/assets/icons/lock-closed.svg" alt="">
                    </div>
                    <div [innerHTML]=" 'plaid.convenient-secure-title' | translate"></div>
                </div>
                <div [innerHTML]=" 'plaid.convenient-secure-list' | translate"></div>
                <button class="continue-btn btn btn-lg btn-primary" (click)="launchPlaid()" [disabled]="isButtonDisabled">{{ 'plaid.continue' | translate }}</button>
            </div>
        </div>
        <app-plaid-login *ngIf="plaidTrigger" 
            (showPlaidContainerEvent) = "setShowPlaidContainer($event)"
            (disableButton) = "disableButton($event)">
        </app-plaid-login>
    </div>
    <ng-container *ngIf="showPlaidSuccessMessage && !showPlaidContainer">
        <div class="success-plaid-box">
            <div class="congrats-header">
                <img src="/assets/icons/check-circle.svg" alt="">
                <div [innerHTML]=" 'plaid.congrats' | translate"></div>
            </div>
            <div [innerHTML]=" 'plaid.verified-account' | translate"></div>
        </div>
        <div class="contact-us" [innerHTML]=" 'plaid.contact-us' | translate"></div>
    </ng-container>
    <div class="footer-message">
        <div [innerHTML]=" 'plaid.verif-no-guarantee' | translate"></div>
        <div [innerHTML]=" 'plaid.best-practices' | translate"></div>
    </div>
</div>
