export enum LocalizationKeysEnum {
  FirstNameRequired = 'validation_messages.fn_required',
  FirstNameMaxLength = 'validation_messages.fn_max_length_30',
  FirstNameAcceptsOnlyLetters = 'validation_messages.fn_accept_only_alphabets',
  LastNameRequired = 'validation_messages.ln_required',
  LastNameMaxLength = 'validation_messages.ln_max_length_30',
  LastNameAcceptsOnlyLetters = 'validation_messages.ln_accept_only_alphabets',
  EmailRequired = 'validation_messages.email_required',
  EmailIncorrect = 'validation_messages.email_incorrect',
  EmailMaxLength = 'validation_messages.email_max_length_200',
  PhoneRequired = 'validation_messages.phone_required',
  OnlyNumbersAllowed = 'validation_messages.only_numbers_allowed_pattern',
  OnlyLettersAndNumbersAllowed = 'validation_messages.only_letters_and_numbers_allowed_pattern',
  PhoneIncorrect = 'validation_messages.phone_incorrect',
  PhoneMaxLength = 'validation_messages.phone_max_length_15',
  Min8 = 'validation_messages.min_8',
  CompanyNameRequired = 'validation_messages.company_name_required',
  CompanyNameMaxLength = 'validation_messages.company_name_length_80',
  MonthlyRevenueRequired = 'validation_messages.revenue_required',
  MonthlyRevenueMaxLength = 'validation_messages.revenue_max_length_7',
  MonthlyRevenueAllowOnlyNumerics = 'validation_messages.revenue_allow_only_numerics',
  OpenedAtYearRequired = 'validation_messages.year_error',
  MinYear = 'validation_messages.year_error_1000',
  MaxYear = 'validation_messages.year_error_past',
  RequestedAmountRequired = 'validation_messages.requested_amount_required',
  Min1 = 'validation_messages.min_1',
  Max500000 = 'validation_messages.max_500000',
  PurposeRequired = 'validation_messages.purpose_required',
  MaxLength100 = 'validation_messages.max_length_100',
  BusinessNameRequired = 'validation_messages.business_name_required',
  MaxLength25 = 'validation_messages.max_length_25',
  MaxLength30 = 'validation_messages.max_length_30',
  MaxLength50 = 'validation_messages.max_length_50',
  MaxLength255 = 'validation_messages.max_length_255',
  FeinRequired = 'validation_messages.fein_required',
  ShouldContain9Symbols = 'validation_messages.should_contain_9_symbols',
  BusinessPhoneRequired = 'validation_messages.business_phone_required',
  LegalStructureRequired = 'validation_messages.legal_structure_required',
  EmployeesNumberRequired = 'validation_messages.employee_number_required',
  EmployeesMaxNumber = 'validation_messages.max_999',
  IndustryRequired = 'validation_messages.industry_required',
  BusinessAddressRequired = 'validation_messages.business_address_required',
  CityRequired = 'validation_messages.city_required',
  StateRequired = 'validation_messages.select_a_state',
  ZipRequired = 'validation_messages.zip_required',
  ShouldContain5Symbols = 'validation_messages.should_contain_5_symbols',
  OwnersNameRequired = 'validation_messages.owners_name_required',
  OwnersLastNameRequired = 'validation_messages.owner_last_name_required',
  OwnershipRequired = 'validation_messages.ownership_required',
  Max100 = 'validation_messages.max_100',
  TotalOwnerShipMax100 = 'validation_messages.total_ownership_max100',
  JobTitleRequired = 'validation_messages.owner_job_title_required',
  OwnerPhoneRequired = 'validation_messages.owner_phone_required',
  OwnerBirthdateRequired = 'validation_messages.owner_birthday_required',
  OwnerAddressRequired = 'validation_messages.owner_address_required',
  OwnerCityRequired = 'validation_messages.owner_city_required',
  OwnerStateRequired = 'validation_messages.owner_state_required',
  OwnerZipRequired = 'validation_messages.owner_zip_required',
  PasswordRequired = 'validation_messages.password_required',
  PasswordIncorrect = 'validation_messages.password_incorrect',
  OpenedAtMonthRequired = 'validation_messages.month_error',
  GeneralInformationStepName = 'flow_steps_names.general',
  BusinessInformationStepName = 'flow_steps_names.business',
  OwnersInformationStepName = 'flow_steps_names.owners',
  DocusignStepName = 'flow_steps_names.docusign',
  ReportsStepName = 'flow_steps_names.reports',
  FinicityStepName = 'flow_steps_names.finicity',
  MinAgeRestriction = 'validation_messages.min_age_restriction',
  MaxAgeRestriction = 'validation_messages.max_age_restriction',
  InvalidFieldFormat = 'validation_messages.invalid_field_format',
  InvalidPhoneNumber = 'validation_messages.invalid_phone_number',
  InvalidEmailAddress = 'validation_messages.invalid_email_address',
  InvalidMaxValue = 'validation_messages.invalid_max_value',
  InvalidMinValue = 'validation_messages.invalid_min_value',
  InvalidMaxLength = 'validation_messages.invalid_max_length',
  InCorrectLength = 'validation_messages.incorrect_value_length',
  InCorrectLengthWithFieldName = 'validation_messages.incorrect_value_length_with_field',
  RequiredWithFieldName = 'validation_messages.required_with_field',
  IsRequired = 'validation_messages.required_message'
}
