<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="card p-4 h-100">
        <img class="card-img-top" src="holder.js/100px180/" alt="" />
        <div class="card-body text-center">
          <h3>
            {{ 'standard_or_finicity.standard_verification' | translate }}
          </h3>
          <h4>
            {{ 'standard_or_finicity.upload_your_documents' | translate }}
          </h4>
          <i class="fa-thin fa-turtle fa-4x pt-3 pb-3"></i>
          <br />
          <button
            routerLink="/application/reports/standard"
            class="btn btn-lg btn-primary"
          >
            Verify Now
          </button>
          <div class="text-start pt-4">
            <p><strong>Convenient & Secure</strong></p>
            <ul>
              <li>{{ 'standard_or_finicity.files_format' | translate }}</li>
              <li>{{ 'standard_or_finicity.file_size' | translate }}</li>
              <li>{{ 'standard_or_finicity.privacy' | translate }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="card p-4 h-100">
        <img class="card-img-top" src="holder.js/100px180/" alt="" />
        <div class="card-body text-center">
          <h3>{{ 'standard_or_finicity.fast_verification' | translate }}</h3>
          <h4>{{ 'standard_or_finicity.securely' | translate }}</h4>
          <br />
          <i class="fa-thin fa-rabbit fa-4x pt-3 pb-3"></i>
          <br />
          <a routerLink="/application/reports/plaid">
            <button class="btn btn-lg btn-primary">
              {{ 'standard_or_finicity.verify_now' | translate }}
            </button>
          </a>
          <div class="text-start pt-4">
            <p><strong>Convenient & Secure</strong></p>
            <ul>
              <li>
                {{ 'standard_or_finicity.secure_online_process' | translate }}
              </li>
              <li>
                {{ 'standard_or_finicity.no_statements_needed' | translate }}
              </li>
              <li>{{ 'standard_or_finicity.fast_offer' | translate }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>