import {
  completeUrl,
  docusignUrl,
  overviewUrl,
  selectFlowUrl,
  plaidFlowUrl
} from '../../../constants';

export const noContinuePages = [
  docusignUrl,
  selectFlowUrl,
  completeUrl,
  overviewUrl,
  plaidFlowUrl
];
