import { ApplicationFlowStepsEnum } from '../components/application-flow/steps-config/application-flow-steps.enum';
import { QuestioneerFlowStepsEnum } from '../components/questioneer-flow/steps-config/questioneer-flow-steps.enum';

export const registerPageUrl: string = '/register';
export const declinePageUrl: string = '/results';
export const loginPageUrl: string = '/login';
export const resetPasswordPageUrl: string = '/reset-password';
export const resetPasswordMailPageUrl: string = '/reset-password-mail';
export const inCollectionsPageUrl: string = '/existing-cd';

export const applicationFlowUrl: string = '/application';
export const generalInfoUrl: string = `${applicationFlowUrl}/${ApplicationFlowStepsEnum.GeneralInfo}`;
export const businessInfoUrl: string = `${applicationFlowUrl}/${ApplicationFlowStepsEnum.BusinessInfo}`;
export const ownersInfoUrl: string = `${applicationFlowUrl}/${ApplicationFlowStepsEnum.OwnersInfo}`;
export const docusignUrl: string = `${applicationFlowUrl}/${ApplicationFlowStepsEnum.Docusign}`;
export const reportsUrl: string = `${applicationFlowUrl}/${ApplicationFlowStepsEnum.Reports}`;
export const selectFlowUrl: string = `${reportsUrl}/select-flow`;
export const standardFlowUrl: string = `${reportsUrl}/standard`;
export const finicityFlowUrl: string = `${reportsUrl}/finicity`;
export const plaidFlowUrl: string = `${reportsUrl}/plaid`;
export const completeUrl: string = `${applicationFlowUrl}/${ApplicationFlowStepsEnum.Complete}`;
export const overviewUrl: string = `${applicationFlowUrl}/${ApplicationFlowStepsEnum.Overview}`;

export const questioneerFlowWildcardUrl: string = '/lp/nt/:any';

export const questioneerFlowUrl: string = '/lp/nt';
export const qflowBusinessOpenUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.BusinessOpen}`;
export const qflowRevenueUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.Revenue}`;
export const qflowStartDateUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.StartDate}`;
export const qflowCheckingAccountUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.CheckingAccount}`;
export const qflowActiveBankruptcyUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.ActiveBankruptcy}`;
export const qflowMoneyNeedUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.MoneyNeed}`;
export const qflowCompanyNameUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.CompanyName}`;
export const qflowEmailUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.Email}`;
export const qflowPhoneNumberUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.PhoneNumber}`;
export const qflowFullNameUrl: string = `${questioneerFlowUrl}/${QuestioneerFlowStepsEnum.FullName}`;

export const affiliateQuestioneerFlowUrl: string = '/lp/af';
export const affiliateQflowBusinessOpenUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.BusinessOpen}`;
export const affiliateQflowRevenueUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.Revenue}`;
export const affiliateQflowStartDateUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.StartDate}`;
export const affiliateQflowCheckingAccountUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.CheckingAccount}`;
export const affiliateQflowActiveBankruptcyUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.ActiveBankruptcy}`;
export const affiliateQflowMoneyNeedUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.MoneyNeed}`;
export const affiliateQflowCompanyNameUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.CompanyName}`;
export const affiliateQflowEmailUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.Email}`;
export const affiliateQflowPhoneNumberUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.PhoneNumber}`;
export const affiliateQflowFullNameUrl: string = `${affiliateQuestioneerFlowUrl}/${QuestioneerFlowStepsEnum.FullName}`;

export const unknownUrl: string = '**';

export const toRouteUrl = (
  route: string,
  takeFullRoute: boolean = false
): string => {
  route = route.substring(1, route.length);

  if (takeFullRoute) {
    return route;
  }

  const slashIndex: number = route.lastIndexOf('/');

  if (slashIndex !== -1) {
    route = route.substring(slashIndex + 1, route.length);
  }

  return route;
};
