import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from './base-api-service';
import {
  RequestDocumentResponseType,
  RequestFileResponse,
  RequestFileResponseType,
} from '../models/responses/request-file.model';
import { filter, map } from 'rxjs/operators';
import {
  PlatformAPI,
  PlatformAPIErrorMessages,
} from '../constants/platform-api';
import { HttpErrorResponse } from '@angular/common/http';
import { MonitoringService, TransactionType } from './monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  public files: BehaviorSubject<RequestFileResponse[]> = new BehaviorSubject<
    RequestFileResponse[]
  >([]);
  plaidSuccessFlagKey = 'is_plaid_successful';

  constructor(
    private monitoringService: MonitoringService,
    private baseApiService: BaseApiService
  ) { }

  public loadFiles(): void {
    this.getUploadedFiles().subscribe((result: RequestFileResponse[]) => {
      if (result.length > 0) {
        this.addFiles(result);
      }
    });
  }

  public addFile(item: RequestFileResponse): void {
    this.addFiles([item]);
  }

  public addFiles(items: RequestFileResponse[]): void {
    this.files.next([...this.files.value, ...items]);
  }

  public getActualFiles(
    type: RequestFileResponseType
  ): Observable<RequestFileResponse[]> {
    return this.files
      .asObservable()
      .pipe(filter((files) => files.some((f) => f.fileType === type)));
  }

  public downloadFile(fileId: number, fileName: string): void {
    const url = `${PlatformAPI.DocumentsFiles}/${fileId}`;
    this.baseApiService.downloadFile(url).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.target = '_blank';
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  public uploadDocument(
    file: File,
    fileName: string
  ): Observable<RequestFileResponse> {
    const resultFileName = fileName || file.name || 'file.pdf';
    const content = new FormData();

    if (file !== null && file !== undefined) {
      content.append('file', file, resultFileName);
    }

    const noThrow = (response: HttpErrorResponse): boolean =>
      response.status === 200 ||
      (response.status === 400 &&
        (response.error?.message.includes(
          PlatformAPIErrorMessages.UnsupportedExtension
        ) ||
          response.error?.message.includes(
            PlatformAPIErrorMessages.EmptyFile
          ) ||
          response.error?.message.includes(
            PlatformAPIErrorMessages.FileTooBig
          )));

    const span = this.monitoringService.startSpan(
      TransactionType.ApiCall,
      `POST ${PlatformAPI.DocumentsUpload}`
    );
    return this.baseApiService
      .sendData<FormData, RequestFileResponse>(
        'post',
        PlatformAPI.DocumentsUpload,
        content,
        true,
        noThrow
      )
      .pipe(
        map((fileResponse) => {
          this.files.next([
            ...this.files.value.filter(
              (x) =>
                this.getManualDocumentType(x) !==
                this.getManualDocumentType(fileResponse)
            ),
            fileResponse,
          ]);
          this.monitoringService.endSpan(span);
          return fileResponse;
        })
      );
  }

  private getUploadedFiles(): Observable<RequestFileResponse[]> {
    const span = this.monitoringService.startSpan(
      TransactionType.ApiCall,
      `GET ${PlatformAPI.DocumentsFiles}`
    );
    return this.baseApiService
      .getData<RequestFileResponse[]>(PlatformAPI.DocumentsFiles, true)
      .pipe(
        map((res) => {
          this.monitoringService.endSpan(span);
          return res;
        })
      );
  }

  private getManualDocumentType(fileResponse: RequestFileResponse): string {
    return fileResponse.fileName.substring(
      0,
      fileResponse.fileName.indexOf('_')
    );
  }

  public setFinicityFilesMetadata(): void {
    this.files.next([
      ...this.files.value,
      {
        id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        fileName: RequestDocumentResponseType.BankStatement1,
        fileType: RequestFileResponseType.Finicity,
      },
      {
        id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        fileName: RequestDocumentResponseType.BankStatement2,
        fileType: RequestFileResponseType.Finicity,
      },
      {
        id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        fileName: RequestDocumentResponseType.BankStatement3,
        fileType: RequestFileResponseType.Finicity,
      },
    ]);
  }

  public setPlaidFilesMetadata(): void {
    this.files.next([
      ...this.files.value,
      {
        id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        fileName: RequestDocumentResponseType.BankStatement1,
        fileType: RequestFileResponseType.Plaid,
      },
      {
        id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        fileName: RequestDocumentResponseType.BankStatement2,
        fileType: RequestFileResponseType.Plaid,
      },
      {
        id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        fileName: RequestDocumentResponseType.BankStatement3,
        fileType: RequestFileResponseType.Plaid,
      },
    ]);
  }

  public setPlaidFlowSuccessFlag(): void {
    sessionStorage.setItem(this.plaidSuccessFlagKey, 'true');
  }

  public getPlaidFlowSuccessFlag(): boolean {
    return !!(sessionStorage.getItem(this.plaidSuccessFlagKey));
  }
}
