import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PlaidStatus } from 'src/app/enum/plaid/agents-email-status-enum';
import { NotificationService } from 'src/app/services/notifications-service';
import { TokenService } from 'src/app/services/token-service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryPlaidComponent implements OnInit {

  public showPlaidContainer = true;
  public showPlaidSuccessMessage = false;
  public showPlaidErrorMessage = false;
  plaidTrigger = false;
  title = 'bank-capture-app';
  isButtonDisabled;

  constructor(private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
    public merchantNotificationService: NotificationService,
    private tokenService: TokenService ) { }

  ngOnInit(): void {}

  launchPlaid(): void{
    this.plaidTrigger = true;
  }

  disableButton(value: boolean): void{
    this.isButtonDisabled = value;
  }

  validations(): boolean {
    let output: boolean = false;
    const leadId: string = this.tokenService
                              .getUserId();

    localStorage.setItem('leadId', leadId);

     if (!leadId) {
        this.merchantNotificationService.showError(null, 'Something went wrong.');
        output = false;
      } else {
        output = true;
      }
    return output;
  }

  setShowPlaidContainer(event: PlaidStatus): void{
    if (event === PlaidStatus.Generated) {// if success then hide the plaid container
      this.showPlaidContainer = false;
      this.showPlaidErrorMessage = false;
      this.showPlaidSuccessMessage = true;
    }
    else if(event === PlaidStatus.Failed){
      this.showPlaidContainer = true;
      this.showPlaidErrorMessage = true;
      this.showPlaidSuccessMessage = false;
    }
  }

};


