import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxPlaidLinkService, PlaidLinkHandler } from 'ngx-plaid-link';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PlaidStatus } from 'src/app/enum/plaid/agents-email-status-enum';
import { PlaidLeadService } from 'src/app/services/plaid/plaid.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TokenService } from 'src/app/services/token-service';
import { FilesService } from 'src/app/services/files.service';
import { standardFlowUrl } from 'src/app/constants/url.const';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plaid-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginPlaidComponent implements OnInit {
  @Output() id;
  @Output() disableButton: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  @Output() showPlaidContainerEvent: EventEmitter<PlaidStatus> =
    new EventEmitter<PlaidStatus>();

  linkTokenSub: Observable<string> | undefined;
  linkToken: string;
  private plaidLinkHandler: PlaidLinkHandler;
  constructor(
    private leadService: PlaidLeadService,
    private loadingService: LoadingService,
    private plaidLinkService: NgxPlaidLinkService,
    private router: Router,
    private tokenService: TokenService,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    this.leadService.leadId = this.tokenService.getUserId();
    this.linkTokenSub = this.leadService.getPlaidLinkToken();
    this.linkTokenSub.subscribe(
      (link_token) => {
        this.linkToken = link_token;
        localStorage.setItem('link_token', link_token);

        this.plaidLinkService
          .createPlaid({
            token: link_token,
            onSuccess: (event) => this.onPlaidSuccess(event),
            onExit: () => this.onPlaidExit(),
            onEvent: (event) => this.onPlaidEvent(event),
          })
          .then((handler: PlaidLinkHandler) => {
            this.plaidLinkHandler = handler;
            this.plaidLinkHandler.open();
          });
      },
      (err) => {
        console.log(err);
        this.router.navigateByUrl(standardFlowUrl);
      },
      () => {}
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onPlaidSuccess(publicToken: any): Promise<void> {
    this.filesService.setPlaidFilesMetadata();
    this.filesService.setPlaidFlowSuccessFlag();
    this.router.navigateByUrl(standardFlowUrl);
  }

  onPlaidExit(): void {
    this.router.navigateByUrl(standardFlowUrl);
  }

  onPlaidEvent(event: any): void {
    console.log(event);
    /* if (event === 'ERROR') {
      this.router.navigateByUrl(standardFlowUrl);
    } */
  }

  onPlaidLoad(event: any): void {
    if (event === 'link_loaded') {
      this.loadingService.endLoading();
      const button = document.getElementById('plaid_button') as HTMLElement;
      button.click();
    }
  }

  onPlaidClick(): void {}

  async exchangePlaidPublicToken(publicToken: string): Promise<void> {
    const source$ = this.leadService.exchangePlaidPublicToken(publicToken);
    await source$.pipe(first()).toPromise();
  }
}
