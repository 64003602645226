import { FormErrorsEnum } from '../../../enum/FormErrors.enum';
import { LocalizationKeysEnum } from '../../../enum/LocalizationKeys.enum';
import { phoneErrorMessagesDictionary } from '../../../constants/form-error-messages';
import { Dictionary } from '../../../interfaces/common';

interface OwnersInfoFormErrorMessagesDictionary {
  firstName: Dictionary;
  lastName: Dictionary;
  ownership: Dictionary;
  jobTitle: Dictionary;
  phone: Dictionary;
  dateOfBirth: Dictionary;
  address: Dictionary;
  city: Dictionary;
  state: Dictionary;
  zipCode: Dictionary;
}

export const ownersInfoFormErrorMessages: OwnersInfoFormErrorMessagesDictionary =
  {
    firstName: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnersNameRequired,
      [FormErrorsEnum.Pattern]:LocalizationKeysEnum.OnlyLettersAndNumbersAllowed,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength255
    },
    lastName: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnersLastNameRequired,
      [FormErrorsEnum.Pattern]:LocalizationKeysEnum.OnlyLettersAndNumbersAllowed,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength255
    },
    ownership: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnershipRequired,
      [FormErrorsEnum.Min]: LocalizationKeysEnum.Min1,
      [FormErrorsEnum.Max]: LocalizationKeysEnum.Max100,
      [FormErrorsEnum.TotalOwenershipMax]: LocalizationKeysEnum.TotalOwnerShipMax100
    },
    jobTitle: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.JobTitleRequired,
      [FormErrorsEnum.Pattern]:LocalizationKeysEnum.OnlyLettersAndNumbersAllowed,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength30
    },
    phone: {
      ...phoneErrorMessagesDictionary,
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnerPhoneRequired,
    },
    dateOfBirth: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnerBirthdateRequired,
    },
    address: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnerAddressRequired,
    },
    city: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnerCityRequired,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength25,
    },
    state: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnerStateRequired,
    },
    zipCode: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.OwnerZipRequired,
    },
  };
