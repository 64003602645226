export class ApiErrorResponse {
  message: string = '';
  errorType: ErrorResponseType | null = null;
  errorId: string = '';
  fieldsValidationErrors: any = null;
}

export enum ErrorResponseType {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_HAVE_PASSWORD = 'USER_NOT_HAVE_PASSWORD',
  USER_PASSWORD_IS_NOT_CORRECT = 'USER_PASSWORD_IS_NOT_CORRECT',
  OWNERS_OWNERSHIP_SUM_IS_NOT_VALID = 'OWNERS_OWNERSHIP_SUM_IS_NOT_VALID',
  OWNERS_MANDATORY_OWNERSHIP_SUM_IS_NOT_VALID = 'OWNERS_MANDATORY_OWNERSHIP_SUM_IS_NOT_VALID',
  PASSWORD_IS_NOT_IN_CORRENT_FORMAT = 'PASSWORD_IS_NOT_IN_CORRENT_FORMAT',
  MANDATORY_OWNERS_NOT_FILLED = 'MANDATORY_OWNERS_NOT_FILLED',
  INPUT_FILE_IS_EMPTY = 'INPUT_FILE_IS_EMPTY',
  INPUT_FILE_EXENTION_IS_NOT_SUPPORTED = 'INPUT_FILE_EXENTION_IS_NOT_SUPPORTED',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  FINICITY_NOT_ALLOWED = 'FINICITY_NOT_ALLOWED',
  DOCUSIGN_SIGNED_OPERATION_NOT_ALLOWED = 'DOCUSIGN_SIGNED_OPERATION_NOT_ALLOWED',
  FINICITY_CREATE_CUSTOMER_ERROR = 'FINICITY_CREATE_CUSTOMER_ERROR',
  FINICITY_TESTING_CUSTOMER_LIMIT_EXCEEDED = 'FINICITY_TESTING_CUSTOMER_LIMIT_EXCEEDED',
  FINICITY_INVALID_AUTHORIZATION_CREDENTIALS = 'FINICITY_INVALID_AUTHORIZATION_CREDENTIALS',
  PLAID_SOMETHING_WENT_WRONG = 'PLAID_SOMETHING_WENT_WRONG'
}
